import { Chip, Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { prepareQuotes, prepareText } from './ChatMessageLLM.helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { documentChatSelectors } from '../../store/files/chat/documentChat.selectors';
import { updateQuote } from '../../store/files/chat/documentChat.slice';

interface ChatMessageLLMProps {
  text: string;
  quotes?: string[];
}

export const ChatMessageLLM = ({ text: textProp, quotes: quotesProp }: ChatMessageLLMProps) => {
  const dispatch = useAppDispatch();
  const selected = useAppSelector(documentChatSelectors.selectQuote);

  const text = useMemo(() => prepareText(textProp), [textProp]);
  const quotes = useMemo(() => prepareQuotes(quotesProp), [quotesProp]);

  return (
    <>
      {text.map(({ quote, value }) =>
        quote ? (
          <Tooltip
            key={value}
            title={quotes[value]}
            classes={{ popper: 'chat-message-quote-tooltip' }}
          >
            <Chip
              label={value}
              onClick={() =>
                dispatch(updateQuote(quotes[value] === selected ? undefined : quotes[value]))
              }
              className={`chat-message-quote-button ${
                quotes[value] === selected ? 'chat-message-quote-button--current' : ''
              }`}
              size='small'
            />
          </Tooltip>
        ) : (
          <span key={value}>{value}</span>
        )
      )}
    </>
  );
};
