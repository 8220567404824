import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import DocumentChatService from './documentChat.service';
import {
  AskQuestionConfig,
  ChatMessage,
  DocumentChatRequest,
  DocumentChatResponse,
  Sender,
} from './documentChat.types';
import { handleResponseError } from '../../error.helpers';
import { Document } from '../documents/documents.list.types';

export interface DocumentChatState {
  error: string | undefined;
  loading: boolean;
  messages: ChatMessage[];
  modal?: Document;
  quote?: string;
  sessionId: string | undefined;
  spinner: boolean;
}

const initialState: DocumentChatState = {
  error: undefined,
  loading: false,
  messages: [],
  modal: undefined,
  quote: undefined,
  sessionId: undefined,
  spinner: false,
};

export const documentChatSlice = createSlice({
  name: 'documentChat',
  initialState,
  reducers: {
    openChatModal: (state, action: PayloadAction<{ document: Document }>) => {
      state.modal = action.payload.document;
    },
    closeChatModal: (state) => {
      state.modal = undefined;
    },
    updateQuote: (state, action: PayloadAction<string | undefined>) => {
      state.quote = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(askQuestion.pending, (state, action) => {
        state.loading = true;
        state.spinner = true;
        state.error = undefined;
        state.messages.push({ sender: Sender.User, text: action.meta.arg.Question });
      })
      .addCase(askQuestion.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.spinner = false;
        state.sessionId = payload.SessionId;
        state.messages.push({ sender: Sender.LLM, text: payload.Answer, quotes: payload.Quotes });
      })
      .addCase(askQuestion.rejected, (state, action) => {
        state.loading = false;
        state.spinner = false;
        state.error = action.payload;
      })
      .addCase(clearChat.pending, (state) => {
        state.loading = true;
        state.error = undefined;
        state.quote = undefined;
      })
      .addCase(clearChat.fulfilled, (state) => {
        state.loading = false;
        state.messages = [];
        state.sessionId = undefined;
      })
      .addCase(clearChat.rejected, (state, action) => {
        state.loading = false;
        if (state.modal) {
          state.error = action.payload;
        } else {
          state.messages = [];
          state.sessionId = undefined;
        }
      });
  },
});

export const askQuestion = createAsyncThunk<
  DocumentChatResponse,
  DocumentChatRequest,
  AskQuestionConfig
>(
  'documentChat/askQuestion',
  async ({ DocumentId, IdModel, Question }, { getState, rejectWithValue }) => {
    try {
      const { sessionId: SessionId } = getState()[documentChatSlice.name];

      const { data } = await DocumentChatService.askQuestion({
        DocumentId,
        IdModel,
        Question,
        SessionId,
      });

      return data;
    } catch (e) {
      return rejectWithValue(handleResponseError(e as Error, true));
    }
  }
);

export const clearChat = createAsyncThunk<void, void, AskQuestionConfig>(
  'documentChat/clearChat',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { sessionId: SessionId } = getState()[documentChatSlice.name];
      if (!SessionId) return;

      await DocumentChatService.deleteSession({ SessionIds: [SessionId] });
    } catch (e) {
      return rejectWithValue(handleResponseError(e as Error, true));
    }
  }
);

export const { openChatModal, closeChatModal, updateQuote } = documentChatSlice.actions;
